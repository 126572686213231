import { render, staticRenderFns } from "./SectionVision.vue?vue&type=template&id=f475c6da&scoped=true&"
import script from "./SectionVision.vue?vue&type=script&lang=js&"
export * from "./SectionVision.vue?vue&type=script&lang=js&"
import style0 from "./SectionVision.vue?vue&type=style&index=0&id=f475c6da&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f475c6da",
  null
  
)

/* custom blocks */
import block0 from "@/lang/SectionVision.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Fsections%2Flanding%2FSectionVision.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomTitle: require('/app/components/atoms/common/AtomTitle.vue').default,OrganismsCommonOrganismTitleAndDescription: require('/app/components/organisms/common/OrganismTitleAndDescription.vue').default})
