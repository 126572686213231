
export default {
  name: 'SectionVision',
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {
          noAnimation: false,
          vision: [],
          slider: []
        }
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    },
    title: {
      required: false,
      type: String,
      default: ''
    },
    subtitle: {
      required: false,
      type: String,
      default: ''
    }
  },
  computed: {
    buildingSvg() {
      return require('assets/images/buildings.svg')
    }
  }
}
